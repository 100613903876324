import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import { LinearGradient, Stop, Rect, Svg, Defs } from 'react-native-svg'

export interface GradientButtonProps {
    colors: string[]
    style?: { [key: string]: string | number }
    children?: React.ReactNode
}

const LinearGradientComponent: React.FC<GradientButtonProps> = ({
    colors,
    children,
    style = {},
}) => {
    const [color1, color2] = colors

    const {
        padding,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingVertical,
        paddingHorizontal,
        backgroundColor, //deconstruct this to ignore it
        ...restStyle
    } = style
    const renderWeb = ({ children }) => (
        <div
            style={{
                display: 'flex',
                backgroundImage: `linear-gradient(to bottom, ${color1}, ${color2})`,
                ...style,
            }}
        >
            {children}
        </div>
    )

    const renderMobile = ({ children }) => (
        <View style={[restStyle]}>
            <Svg width="100%" height="100%">
                <Defs>
                    {/* @ts-ignore */}
                    <LinearGradient id="grad" x1="0" y1="0" x2="100%" y2="0">
                        {colors.map((color, index) => (
                            <Stop
                                key={index}
                                offset={`${(index * 100) / (colors.length - 1)}%`}
                                stopColor={color}
                                stopOpacity={
                                    colors.length
                                        ? index == 0
                                            ? '0'
                                            : '1'
                                        : '1'
                                }
                            />
                        ))}
                    </LinearGradient>
                </Defs>
                <Rect
                    rx={style.borderRadius}
                    ry={style.borderRadius}
                    width="100%"
                    height="100%"
                    fill={`url(#grad)`}
                />
                <View
                    style={[
                        {
                            height: '100%',
                            display: 'flex',
                            ...restStyle,
                        },
                        {
                            padding,
                            paddingTop,
                            paddingBottom,
                            paddingLeft,
                            paddingRight,
                            paddingVertical,
                            paddingHorizontal,
                        },
                    ]}
                >
                    {children}
                </View>
            </Svg>
        </View>
    )

    return Platform.OS === 'web'
        ? renderWeb({ children })
        : renderMobile({ children })
}

export default LinearGradientComponent
